<template>
  <v-app>
    <v-container>
      <v-app-bar>
        <v-app-bar-title>
          <v-btn href="#/">HOME</v-btn>
          <v-btn href="#/hello">Hello</v-btn>
          <v-btn href="#/form">Form</v-btn>
          <v-btn href="#/chart">Analizler</v-btn>
        </v-app-bar-title>
      </v-app-bar>
    </v-container>
    <div>
    <component
      :is="currentView.component"
      v-bind="currentView.props"
    ></component>
  </div>
  </v-app>

  
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import FormComp from "./components/FormComp.vue";
import HomeComp from "./components/HomeComp.vue";
import NotFound from "./components/NotFound.vue";
// eslint-disable-next-line no-unused-vars
import ChartComp from "./components/ChartComp.vue";

const routes = {
  "/": { component: HomeComp, props: {} },
  "/hello": { component: HelloWorld, props: { msg: "Merhaba" } },
  "/form": { component: FormComp, props: {} },
  "/chart": { component: ChartComp, props: {} },
};

export default {
  data() {
    return {
      currentPath: window.location.hash,
    };
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || "/"] || NotFound;
    },
  },
  mounted() {
    window.addEventListener("hashchange", () => {
      this.currentPath = window.location.hash;
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
}
</style>
