<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div class="photo-div">
      <img class="self-photo" src="../assets/5x6.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>


<style scoped>
.photo-div{
  height: 500px;
  width: 100%;
  overflow: hidden;


background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);

  display: flex;
  justify-content: flex-end;
}

.self-photo{
object-fit: contain;
}


</style>
